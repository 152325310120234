import { api } from '../boot/axios';

const url = '/api/region';

export default {
  namespaced: true,


  actions: {
    async load({ commit }, payload) {
      const res = await api.get(`${url}/`, payload);
      commit('setAll', { data: res.data.data });
    },
  },


  mutations: {
    setAll(state, { data }) {
      state.all = data;
    },
  },


  state: {
    all: [],
  },


  getters: {
    getAll(state) {
      return state.all;
    },
  },
};
