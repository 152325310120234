import { api } from '../boot/axios';

const url = '/api/company-settings';
const logoUrl = '/api/file/logo';

export default {
  namespaced: true,


  actions: {
    async loadPdfSettings({ commit }, payload) {
      const res = await api.get(`${url}/load-pdf-settings`, payload);
      commit('setPdfSettings', { data: res.data.data });
    },
    async save({ commit }, payload) {
      await api.post(`${url}/store`, payload);
    },
    async uploadLogo({ commit }, payload) {
      const formData = new FormData();

      formData.append('name', payload.fileName);
      formData.append('document_type', payload.file.type);
      formData.append('file', payload.file);

      const res = await api.post(`${url}/logo-upload`, formData, { 'content-type': 'multipart/form-data' });

      return res?.data?.data;
    },
    async loadLogo({ commit }) {
      const res = await api.get(`${logoUrl}`, { responseType: 'blob' });
      commit('setLogo', { data: res });
    },
  },


  mutations: {
    setPdfSettings(state, { data }) {
      state.pdfsettings = data;
    },
    setLogo(state, { data }) {
      state.logo = data;
    },
  },


  state: {
    pdfsettings: [],
    logo: [],
  },


  getters: {
    getPdfSettings(state) {
      return state.pdfsettings;
    },
    getLogo(state) {
      return state.logo;
    },
  },
};
