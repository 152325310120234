import _ from 'lodash';
import { version, versionDate } from '../../package.json';
import { api } from '../boot/axios';
import { LocalStore } from '../utils/localStoreHelper';

const url = '/api/auth';

export default {
  namespaced: true,


  actions: {
    async getVersion({ commit }, payload) {
      const res = await api.get('/api/get-version');
      commit('setVersion', { data: res.data.data });
    },
    async login({ commit }, payload) {
      await api.get('/sanctum/csrf-cookie');
      const res = await api.post(`${url}/login`, payload);
      commit('login', { data: res.data });
    },
    async logout({ commit }) {
      try {
        await api.post(`${url}/logout`);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Logout - api already logged out');
      }
      commit('logout');
    },
    async forgotPw({ commit }, payload) {
      await api.post(`${url}/forgot-password`, payload);
    },
    async resetPw({ commit }, payload) {
      await api.post(`${url}/reset-password`, payload);
    },
    async changePw({ commit }, payload) {
      await api.post(`${url}/change-password`, payload);
    },
    async me({ commit }, payload) {
      const res = await api.get(`${url}/me`, payload);
      commit('setMe', { data: res.data.data });
    },
  },


  mutations: {
    setVersion(state, { data: apiVersion }) {
      state.version = apiVersion;
      state.version.webVersion = version;
      state.version.webVersionDate = versionDate;
      state.version.release = `api v${state.version.apiVersion} | web v${state.version.webVersion}`;
      LocalStore.storeVersion(state.version);
    },
    login(state, { data }) {
      if (data.user !== undefined && data.token !== undefined) {
        state.data = data.user;
        LocalStore.storeUser(data.user);
        LocalStore.storeToken(data.token);
      }
    },
    logout(state) {
      state.data = {};
      LocalStore.clear();
    },
    setMe(state, { data }) {
      state.me = data;
    },
  },


  state: {
    data: {},
    version: {},
    me: {},
  },


  getters: {
    getVersion(state) {
      return _.isEmpty(state.version) ? LocalStore.getVersion() : state.version;
    },
    getData(state) {
      return _.isEmpty(state.data) ? LocalStore.getUser() : state.data;
    },
    isAuth(state) {
      return !_.isEmpty(state.data);
    },
    isDeveloper() {
      return LocalStore.getDevHelper();
    },
    hasRole(state) {
      return (role) => {
        const user = _.isEmpty(state.data) ? LocalStore.getUser() : state.data;
        if (user.roles[role]) {
          return true;
        }
        return false;
      };
    },
    can(state) {
      return (ability) => {
        const user = _.isEmpty(state.data) ? LocalStore.getUser() : state.data;
        if (user.abilities[ability]) {
          return true;
        }
        return false;
      };
    },
    getMe(state) {
      return state.me;
    },
  },
};
