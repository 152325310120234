import { api } from '../boot/axios';

const url = '/api/relation';
const name = 'Relation';

export default {
  namespaced: true,

  actions: {
    async getByItemId({ commit }, payload) {
      if (payload.itemId && payload.countryId) {
        const res = await api.get(`${url}/get-by-item-id/${payload.itemId}/${payload.countryId}`, payload);
        commit('setByItemId', { data: res.data.data, id: `${payload.itemId}` });
      } else {
        throw new Error(`${name} - some IDs are missing.`);
      }
    },
    async getByItemIdAll({ commit }, payload) {
      if (payload.itemId && payload.countryId) {
        const res = await api.get(`${url}/get-by-item-id-all/${payload.itemId}/${payload.countryId}`, payload);
        commit('setByItemIdAll', { data: res.data.data, id: `${payload.itemId}` });
      } else {
        throw new Error(`${name} - some IDs are missing.`);
      }
    },
    async getGroups({ commit }, payload) {
      if (payload.itemId) {
        const res = await api.get(`${url}/get-groups/${payload.itemId}`, payload);
        commit('setGroups', { data: res.data.data, id: `${payload.itemId}` });
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async storeGroups({ commit }, payload) {
      if (payload?.name !== undefined && payload.deleted && payload.added) {
        await api.post(`${url}/store-groups/`, payload);
      } else {
        throw new Error(`${name} - some arguments are missing from payload.`);
      }
    },
    async storeByItemId({ commit }, payload) {
      if (payload.itemId && payload.relationName && payload.deleted && payload.added && payload.member) {
        await api.post(`${url}/store-by-item-id/`, payload);
      } else {
        throw new Error(`${name} - some arguments are missing from payload.`);
      }
    },
  },

  mutations: {
    setByItemId(state, { data, id }) {
      state.byItemId[id] = data;
    },
    setByItemIdAll(state, { data, id }) {
      state.byItemIdAll[id] = data;
    },
    setGroups(state, { data, id }) {
      state.groups[id] = data;
    },
  },

  state: {
    groups: {},
    byItemId: {},
    byItemIdAll: {},
  },

  getters: {
    getByItemId(state) {
      return (id) => state.byItemId[id];
    },
    getByItemIdAll(state) {
      return (id) => state.byItemIdAll[id];
    },
    getGroups(state) {
      return (id) => state.groups[id];
    },
  },
};
