import { api } from '../boot/axios';

const url = '/api/main';
const name = 'Variation data';

export default {
  namespaced: true,

  actions: {
    async search({ commit }, payload) {
      if (payload.countryId != null && payload.guidelineId != null && payload.globalMainId) {
        const res = await api.get(`${url}/search/${payload.countryId}/${payload.guidelineId}/${payload.globalMainId}`);
        commit('setSearch', { data: res.data.data, id: `${payload.countryId}-${payload.guidelineId}-${payload.globalMainId}` });
      } else {
        throw new Error(`${name} - Some ids are missing.`);
      }
    },
    async getByVarCode({ commit }, payload) {
      if (payload.countryId && payload.guidelineId && payload.mainVarCode) {
        const res = await api.get(`${url}/get-by-var-code/${payload.countryId}/${payload.guidelineId}/${payload.mainVarCode}`, payload);
        commit('setByVarCode', { data: res.data.data, id: `${payload.countryId}-${payload.guidelineId}-${payload.mainVarCode}` });
      } else {
        throw new Error(`${name} - Some ids are missing.`);
      }
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async destroy({ commit }, payload) {
      if (payload.id) {
        await api.delete(`${url}/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async approve({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/approve/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async reject({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/reject/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async loadByGuideline({ commit }, payload) {
      if (payload.guidelineId) {
        const res = await api.get(`${url}/load-by-guideline/${payload.guidelineId}`, payload);
        commit('setByGuideline', { data: res.data.data, guidelineId: payload.guidelineId });
      } else {
        throw new Error(`${name} - guidelineId not found.`);
      }
    },
  },

  mutations: {
    setSearch(state, { data, id }) {
      state.search[id] = data;
    },
    setByVarCode(state, { data, id }) {
      state.byVarCode[id] = data;
    },
    setByGuideline(state, { data, guidelineId }) {
      state.varCodesByGuideline[guidelineId] = data;
    },
  },

  state: {
    all: [],
    search: {},
    byVarCode: {},
    nonValidated: [],
    varCodesByGuideline: {},
  },

  getters: {
    getSearch(state) {
      return (countryId, guidelineId, globalMainId) => state.search[`${countryId}-${guidelineId}-${globalMainId}`];
    },
    getByVarCode(state) {
      return (countryId, guidelineId, mainVarCode) => state.byVarCode[`${countryId}-${guidelineId}-${mainVarCode}`];
    },
    loadByGuideline(state) {
      return (id) => state.varCodesByGuideline[id];
    },
  },
};
